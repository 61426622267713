<template>
  <div id="auth-layout">
    <div class="router-view-area">
      <router-view></router-view>
    </div>
    <Footer/>
  </div>
</template>

<script>
import Footer from "../components/Footer";
export default {
  components: {Footer}
}


</script>