<template>
  <div id="default-layout">
    <Header/>
    <div class="router-view-area">
      <b-overlay :show="loading" rounded="sm">
        <router-view></router-view>
      </b-overlay>
    </div>
    <Footer/>
  </div>
</template>

<script>
import Header from "../components/Header";
import Footer from "../components/Footer";
import {mapState} from "vuex";
export default {
  components: {Header, Footer},
  computed:{
    ...mapState({
      loading: state => state.loading
    })
  }
}

</script>
<style lang="scss">
.router-view-area{
  min-height:70vh;
}
</style>