<template>

  <div id="products">

    <div class="container">
      <div class="row align-items-center">
        <div class="col-12">
          <h2>Scan products</h2>
        </div>

      </div>
    </div>


    <div class="container mt-5">
      <div class="row">

        <div class="col-12">
          <b-form-input autofocus v-model="barcode" debounce="500" placeholder="Click here and scan a barcode"></b-form-input>
        </div>
        <div v-for="product in products" :key="product.id" class="col-12">


          <product-barcode-list :product="product" @stockUpdated="getProducts"/>


        </div>
        <div v-if="products.length === 0" class="col-12 text-center mt-5">

          No products found. Scan a barcode or type the barcode number!
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import ProductBarcodeList from "@/components/Products/ProductBarcodeList";
export default{
  components: {ProductBarcodeList},

  data(){
    return{
      products: [],
      barcode: null
    }
  },
  watch:{
    barcode(){
      this.getProducts();
    }
  },

  mounted(){
    if(this.$route.query.barcode) this.barcode = this.$route.query.barcode;
  },

  methods:{
    getProducts(){
      if(!this.barcode){
        this.products = [];
        return;
      }

      this.axios.get('/admin/products?barcode=' +this.barcode).then(res=>{
        this.products = res.data.rows
      }).catch(()=>{
        this.$notify({type: 'error', title:'Something went wrong', text: 'Please try again later!'});
      })
    }
  }

}
</script>