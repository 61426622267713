<template>
  <b-card class="shadow-sm product-preview-card my-1" >

    <div class="d-flex align-items-center">

      <image-preview width="60" height="60" :url="product.featured_image" class=" d-flex mr-4"/>
      <div class="font-weight-bold mr-4"><span class="text-muted title-label">Product Name</span><br>{{product.name}}</div>
      <div class="font-weight-bold mr-4"><span class="text-muted title-label">Product Price</span><br>{{product.price}} €</div>

      <div class="font-weight-bold mr-4"><span class="text-muted title-label">Product Stock</span><br>{{product.stock_quantity}}</div>
      <div class="font-weight-bold mr-4"><span class="text-muted title-label">Actions</span><br>

        <b-button size="xs" variant="white" title="Remove 1" v-b-tooltip.hover.bottom @click="updateStock(-1)"><span class="feather icon-minus"></span> </b-button>
        <b-button size="xs" variant="primary" title="Add 1"  v-b-tooltip.hover.bottom @click="updateStock(1)"><span class="feather icon-plus"></span> </b-button>

        <b-button size="xs" variant="outline" title="Adjust amount" @click="customAmount = !customAmount"><span class="feather icon-edit"/></b-button>
      </div>

    </div>

    <div v-if="customAmount">

      <div class="row my-4 text-right align-items-center">
        <div class="col-auto ml-auto">
          <div class="text-left">
            <label>
              Custom amount
            </label>
            </div>
          <b-form-input type="number" v-model="amount" style="width:150px"/>
        </div>
        <div class="col-auto">
          <b-button size="xs" variant="white" :title="'Remove '+ amount" v-b-tooltip.hover.bottom @click="updateStock(-1*amount)"><span class="feather icon-minus"></span> </b-button>
          <b-button size="xs" variant="primary" :title="'Add ' + amount"  v-b-tooltip.hover.bottom @click="updateStock(amount)"><span class="feather icon-plus"></span> </b-button>

        </div>
      </div>
    </div>


    <b-modal :ref="'product-' + product.id">

    </b-modal>

  </b-card>
</template>

<style lang="scss" scoped>
.card{
  .d-flex > div{
    width:15%;
  }
}
</style>

<script>
export default{
  props:{
    product: Object
  },

  data(){
    return{
      customAmount: false,
      amount: 1
    }
  },
  methods:{

    updateStock(val){
      this.axios.put('/admin/products/' + this.product.id + '/stock', {value: val}).then(()=>{
        this.$emit('stockUpdated');
      }).catch(()=>{
        this.$notify({type: 'error', title:'Something went wrong', text: 'Please try again later!'});
      })
    }
  }
}
</script>