<template>
  <b-card class="shadow-sm product-preview-card py-0 my-1" @click="redirectTo('edit-product', {productId: product.id})">

    <div class="d-flex align-items-center">

      <image-preview width="60" height="60" :url="product.featured_image" class=" d-flex mr-4"/>

      <div class="font-weight-bold mr-4"><b-badge v-if="isDraft" variant="primary">draft</b-badge> <br v-if="isDraft"/>
        <span class=" title-label">Όνομα</span>
        <br>{{product.name}}
      </div>
      <div class="font-weight-bold mr-4"><span class=" title-label">Τιμή</span><br>{{product.price}} €</div>

      <div class="font-weight-bold mr-4"><span class=" title-label">Απόθεμα</span><br>{{product.stock_quantity}}</div>
    </div>


  </b-card>
</template>

<style lang="scss" scoped>
.product-preview-card{
  font-size:14px;
}
.card{
  &:hover{
    background-color:$primary_shade;
    cursor:pointer;
  }
  .d-flex > div{
    width:15%;
  }
}
</style>

<script>
export default{
  props:{
    product: Object
  },
  computed:{
    isDraft(){
      return this.product.publish_status === 'draft'
    }
  },
  methods:{
    deleteProduct(){
      this.$emit('productDeleted');
    }
  }
}
</script>