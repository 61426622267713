<template>

  <div id="products">

    <div class="container-large">
      <div class="row align-items-center">
        <div class="col-auto">
          <h2>Προϊόντα</h2>
        </div>
        <div class="col-auto ml-auto ext-right">
          <b-button class="ml-auto mr-2" size="md" variant="outline-primary" @click="redirectTo('products-barcode-scan')">Barcode Scan</b-button>
          <b-button class="ml-auto" size="md" variant="primary" @click="redirectTo('products-create')">Προσθήκη νέου</b-button>
        </div>
      </div>
    </div>

    <div class="container-large my-3">

      <div class="row">

        <div class="col-4">
          <b-form-input v-model="query.search" debounce="500" placeholder="Αναζήτηση..."></b-form-input>
        </div>
      </div>
    </div>


    <div class="container-large mt-5">
      <div class="row">
        <div v-for="product in products" :key="product.id" class="col-12">
          <product-list :product="product" @productDeleted="getProducts"/>
        </div>

        <div v-if="products.length === 0" class="col-12 text-center mt-5">
          <span class="feather icon-cloud-off" style="font-size:50px;"/><br>
            Δεν βρέθηκαν προϊόντα!<br>
        </div>


        <div class="col-12 my-4">
          <b-pagination
              align="right"
              v-model="query.page"
              :total-rows="totalProducts"
              :per-page="query.limit"
              aria-controls="my-table"
          ></b-pagination>

        </div>
      </div>
    </div>
  </div>

</template>

<script>
import ProductList from "@/components/Products/ProductList";
export default{
  components: {ProductList},

  data(){
    return{
      products: [],
      totalProducts: 0,
      query:{
        limit: 50,
        page: 1,
        search: '',

      }
    }
  },

  watch:{

    'query.page'(){
      this.getProducts();
    },

    'query.search'(){
      this.query.page = 1;
      this.getProducts()
    }

  },

  mounted(){

    this.getProducts()
    if(this.$route.query.page) {
      this.query.page = this.$route.query.page
    }
  },

  methods:{
    getProducts(){

      let query = {...this.query};

      query.offset = (query.page-1) * query.limit

      this.axios.get('/admin/products', {params: query}).then(res=>{
        this.products = res.data.rows;
        this.totalProducts = res.data.count;
      }).catch(()=>{
        this.$notify({type: 'error', title:'Something went wrong', text: 'Please try again later!', position: 'bottom center'});
      })
    }
  }

}
</script>