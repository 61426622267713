<template>

  <div id="edit-product">

    <div v-if="product" class="p-4">
      <product-details :product="product"/>
    </div>


  </div>
</template>


<script>
import ProductDetails from "@/components/ProductDetails";
export default{

  components: {ProductDetails},

  mounted(){
    this.fetchProduct();

  },

  methods:{
    fetchProduct(){
      this.axios.get('/admin/products/'+ this.$route.params.productId).then(res=>{
        this.product = res.data;
        if(!this.product.metadata) this.product.metadata = {
          eofCode: null,
          shape: null,
          icons: []
        };
      }).catch(()=>{
        this.$notify({type: 'error', title:'Something went wrong', text: 'Please try again later!'});
      })
    }
  },
  data(){
    return{
      product: null
    }
  }

}
</script>