<template>

  <div id="new-product">

    <div class="container">

      <product-details/>
    </div>


  </div>
</template>


<script>
import ProductDetails from "@/components/ProductDetails";
export default{

  components: {ProductDetails}

}
</script>