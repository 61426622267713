<template>

  <div id="orders">

    <div class="container-large">
      <div class="row align-items-center">
        <div class="col-auto">
          <h2>Παραγγελίες</h2>
        </div>
        <div class="col-auto ml-auto ext-right">
          <b-button size="sm" variant="primary" class="mr-3" @click="createPickupList">
            Δημιουργία λίστας παραλαβής ACS
          </b-button>

          <b-button size="sm" variant="outline-primary" @click="viewDayPickupLists">
            Προβολή λιστών παραλαβής
          </b-button>

        </div>
      </div>
    </div>

    <div class=" container-large my-3">

      <div class="row">

        <div class="col-12 col-md-6">
          <b-form-input v-model="query.search" debounce="500" placeholder="Αναζήτηση (π.χ. αρ.παραγγελίας, όνομα, email, voucher no)" class="py-2"></b-form-input>
        </div>
      </div>
    </div>

    <div class="container-large">
      <div class="d-flex align-items-center filters-wrap mt-4">

        <div v-for="item in statuses" :key="item.title" @click="query.status = item.status" class="status-filter mb-4 flex-shrink-0 cursor-pointer mr-3"
        :class="item.status === query.status ? 'active' : ''">
          {{item.title}}
        </div>

      </div>
    </div>


    <div class="container-large mt-2">
      <div class="row">

        <div class="col-12 mb-3 text-right">

          <b-button size="xs" variant="primary" class="mr-2" :disabled="!$refs['orders-table'] || $refs['orders-table'].selectedRows.length === 0" @click="massActions">
            Δημιουργία ACS vouchers
          </b-button>

          <b-button size="sm" variant="primary" @click="getOrders"><span class="icon-refresh-cw"></span></b-button>
        </div>
        <div class="col-12">

          <vue-good-table
              ref="orders-table"
              mode="remote"
              :pagination-options="{
                enabled: true,
                total: totalOrders,
                setCurrentPage: this.query.page,
                dropdownAllowAll: false,
                perPage: this.query.limit,
                rowsPerPageLabel: 'Ανά σελίδα',
                nextLabel:'Επόμενη',
                prevLabel: 'Προηγούμενη',
                perPageDropdown: [10, 15, 20, 25],
              }"
              :totalRows="totalOrders"
              :sort-options="{ enabled: false }"
              style-class="vgt-table striped condensed"
              theme="polar-bear"
              @on-page-change="params => this.query.page = params.currentPage"
              @on-row-click="rowClicked"
              @on-per-page-change="perPageChanged"
              :columns="columns"
              :rows="orders"
              :select-options="{
                  enabled: true,
                  selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
                  selectionInfoClass: 'custom-class',
                  selectionText: 'rows selected',
                  clearSelectionText: 'clear',
                  disableSelectInfo: true, // disable the select info panel on top
                  selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
                }">
            <template slot="table-row" slot-scope="props">
              <div v-if="props.column.field === 'id'">
                <span class="font-weight-bold">#{{props.row.id}}</span>
              </div>
              <div v-else-if="props.column.field==='status'">
                    <span :style="`font-size:0.9rem;font-weight:bold;color:${status_colors[props.row.status]};`">{{$store.getters.getStatus(props.row.status)}}</span>
              </div>
              <div v-else-if="props.column.field === 'fullName'">
                {{props.row.firstName}} {{props.row.lastName}}
              </div>
              <div v-else-if="props.column.field === 'coupon'">

                {{props.row.order_coupon ? props.row.order_coupon.name : '-'}}
              </div>
              <div v-else-if="props.column.field === 'shipping'">

                <div v-if="!props.row.order_shipping">

                  <span v-if="props.row.status === 'PROCESSING'">

                    <span v-if="props.row.shippingMethod !== 'courier'">
                      Παραλαβή από κατάστημα
                    </span>
                    <span v-else>
                         Δεν έχει αποσταλλεί.
                    </span>

                  </span>
                  <span v-else>
                    -
                  </span>

                </div>
                <div v-else class="print-voucher">

                  <div v-if="props.row.status === 'COMPLETED'">
                    Έχει παραδοθεί.
                  </div>
                  <div v-else>
                    <b-button size="xs" variant="primary" @click="printVoucher(props.row.order_shipping)">Εκτύπωση Voucher</b-button>
                    <div v-if="!props.row.order_shipping.voucher_url" class="text-danger mt-3" style="font-size:0.8rem;">
                      <span class="icon-x"></span> Voucher not printed
                    </div>
                    <div v-else style="font-size:0.8rem" class="mt-3" >Αρ. voucher: {{props.row.order_shipping.voucher_code}}</div>
                  </div>

                </div>

              </div>
              <div v-else-if="props.column.field === 'total'">
                {{props.row.total}}€
              </div>
              <div v-else>

                {{props.formattedRow[props.column.field]}}
              </div>
            </template>

            <div slot="emptystate">
              Δεν βρέθηκαν παραγγελίες
            </div>
          </vue-good-table>
        </div>
      </div>

      <b-modal ref="pick-up-list-modal" title="ACS Λίστα παραλαβών ημέρας" hide-footer>

        <div class="mb-4">
          <label>Επιλογή ημερομηνίας</label>
          <date-picker v-model="pickUpDate" :valueType="'YYYY-MM-DD'" class="w-100"></date-picker>

        </div>


        <div v-for="list of pickUpLists" :key="list.PickupList_No" class="py-2 my-2 d-flex border-bottom">


          <div>Κωδικός Λίστας: {{list.PickupList_No}}
            <b-button variant="primary" class="ml-3" @click="showPickupList(list)"><span class="icon-printer"></span></b-button></div>
        </div>
        <div v-if="pickUpLists.length === 0">



          <div v-if="isSunday">
            Δεν παραλαμβάνονται αποστολές την Κυριακή.
          </div>
          <div v-else>
            Δεν βρέθηκαν οριστικοποιημένες λίστες σήμερα.
          </div>

        </div>
      </b-modal>
    </div>
  </div>

</template>
<style lang="scss">
.filters-wrap{
  overflow-y: auto;
  > div {
    font-size:14px;
  }
}
.status-filter{
  border-bottom:1px solid;
  border-color:transparent;
  &.active{
    font-weight:bold;
    border-bottom:1px solid;
    border-color: $primary;
  }
}
.order-item{
  cursor:pointer;
  margin-top:2px;
  &:nth-child(even){
    background-color:$primary_shade;
  }
  border-color:$primary_shade;
  &:hover{
    background-color:$primary_shade;
  }
  > div{
    font-size:13px;
    font-weight:500;
  }
}
</style>
<script>
import {mapState} from "vuex";

export default{

  data(){
    return{
      orders: [],
      totalOrders: 0,
      query:{
        limit: 10,
        page: 1,
        search: '',
        status: null
      },
      pickUpLists: [],
      pickUpDate: new Date().toISOString().slice(0,10),
      loaded: false,
      columns: [
        {label: '#Id', field: 'id'},
        {label: 'Ποσό', field: 'total'},
        {label: 'Κατάσταση', field: 'status'},
        {label: 'Όνομα', field: 'fullName'},
        {label: 'E-mail', field: 'email'},
        {label: 'Τηλέφωνο', field: 'phone'},
        {label: 'Κουπόνι', field: 'coupon'},
        {label: 'Ημερομηνία', field: 'createdAt', formatFn: value =>
          { return this.formatDateWithTime(new Date(value))}
        },
        {label: 'Κατ. Αποστολής', field: 'shipping', tdClass:'print-voucher'},
      ]
    }
  },
  computed:{
    ...mapState({
      statuses: state => state.statuses,
      status_colors: state => state.status_colors
    }),

    isSunday(){
      return new Date(this.pickUpDate).getDay() === 0;
    }
  },

  watch:{

    'query.page'(val){
      if(!this.loaded) return;
      this.fixUrlQuery('page', val);
      this.getOrders();
    },

    'query.status'(val){
      if(!this.loaded) return;
      this.query.page = 1;
      this.fixUrlQuery('status', val);
      this.getOrders()
    },

    'query.search'(val){
      if(!this.loaded) return;
      this.query.page = 1;
      this.fixUrlQuery('search', val);
      this.getOrders()
    },

    'query.limit'(val){
      if(!this.loaded) return;
      this.query.page = 1;
      this.fixUrlQuery('limit', val);
      this.getOrders()
    },
    pickUpDate(){
      return this.getDayPickupLists();
    }

  },

  mounted(){


    if(this.$route.query.page) this.query.page = parseInt(this.$route.query.page)
    if(this.$route.query.search) this.query.search = this.$route.query.search;
    if(this.$route.query.status) this.query.status = this.$route.query.status;
    if(this.$route.query.limit) this.query.limit = parseInt(this.$route.query.limit)


    this.getOrders()
  },

  methods:{

    perPageChanged(params){
      this.query.limit = params.currentPerPage;
    },
    rowClicked(params){
      let event = params.event;

      let isCheckbox = false;
      if(event.path && event.path.length > 0){

        for(let path of event.path){
          if(path && path.classList && (path.classList.contains('vgt-checkbox-col') || path.classList.contains('print-voucher'))){
            isCheckbox = true;
            break;
          }
        }
      }

      if(isCheckbox){
        return;
      }else{
        this.$router.push({name: 'orders-slug', params: {orderId: params.row.id}})
      }
    },
    fixUrlQuery(key, value){
      let query = {...this.$route.query}

      if(!value) delete query[key]
      else query[key] = value;
      this.$router.push({query})
    },
    getOrders(){

      let query = {...this.query};

      query.offset = (query.page-1) * query.limit

      this.axios.get('/admin/orders', {params: query}).then(res=>{
        this.orders = res.data.rows;
        this.totalOrders = res.data.count;
        this.loaded = true;
      }).catch(()=>{
        this.$notify({type: 'error', title:'Something went wrong', text: 'Please try again later!'});
      })
    },

    massActions(){

      let orders = this.$refs['orders-table'].selectedRows.map(x => x.id);


      if(orders.length === 0) return;
      if(orders.length === 1){
        this.axios.post('/admin/orders/'+ orders[0] + '/voucher').then(()=> {
          this.getOrders();
        }).catch(e=>{
          console.log(e);
        })
      }
      if(orders.length > 1){
        this.axios.post('/admin/orders/voucher/mass', {orderIds: orders}).then(()=> {
          this.getOrders();
        }).catch(e=>{
          console.log(e);
        })
      }

    },

    printVoucher(voucher){
      if(!voucher.voucher_url){
        this.axios.get('/admin/orders/'+ voucher.orderId + '/voucher/print').then(res=> {

          voucher.voucher_url = res.data.url;
          if(res.data.url){
            window.open(res.data.url, "_blank", 'width=600,height=1000');
          }
        }).catch(e=>{
          console.log(e);
        })
      }else{
        window.open(voucher.voucher_url, "_blank", 'width=600,height=1000');
      }

    },

    createPickupList(){

      this.axios.post('/admin/orders/voucher/pick-up-lists').then(res=>{

        if(!res.data.PickupList_No){
          this.$notify({type: 'error', title:'Something went wrong', text: res.data.error || 'Something went wrong'});
          return;
        }

        this.showPickupList({...res.data, Pickup_date: new Date().toISOString().slice(0,10) });
      }).catch(e=>{
        this.$notify({type: 'error', title:'Something went wrong', text: e.response.data.message || 'Something went wrong'});
        console.log(e);
      })
    },


    getDayPickupLists(){
      this.axios.get('/admin/orders/voucher/pick-up-lists', {params: {date: this.pickUpDate.slice(0,10)}}).then(res=>{
        this.pickUpLists = res.data;
      }).catch(e =>{
        console.log(e);
      })
    },

    viewDayPickupLists(){

      this.pickUpDate = new Date().toISOString().slice(0,10);
      this.getDayPickupLists();
      this.$refs['pick-up-list-modal'].show();

    },

    showPickupList(list){

      this.axios.get(`/admin/orders/voucher/pick-up-lists/print?listId=${list.PickupList_No}&date=${list.Pickup_date}`).then(res=>{

        window.open(res.data, "_blank");
      }).catch(e=>{
        console.error(e);
        this.$notify({type: 'error', title:'Something went wrong', text: e.response.data.message || 'Something went wrong'});
      })
      //
    }


  }

}
</script>