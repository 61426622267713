<template>

  <div id="settings">
    <div class="container">
      <div class="row">
        <h2>Settings</h2>


        <div class="col-12 p-0 mt-4">
          <b-tabs>
            <b-tab title="Global Site Notification">

              <global-notification></global-notification>

            </b-tab>
          </b-tabs>

        </div>

      </div>
    </div>
  </div>

</template>

<script>
import GlobalNotification from "@/components/Settings/GlobalNotification";
export default{


  components:{GlobalNotification},
  mounted(){

  },
  methods:{
  },
  data(){
    return{
    }
  }
}
</script>