
<template>
  <div class="p-3">


    <b-form-checkbox v-model="notification.show" id="show-notification" name="show-notification" class="flex align-items-center">
      Enable global site notification
    </b-form-checkbox>

    <div v-if="notification.show" class="mt-3">
      <label>Global notification text</label>
      <b-form-input v-if="notification.show" v-model="notification.text" placeholder="Global notification text"/>
    </div>

    <div class="mt-4 text-right">
      <b-button variant="success" @click="saveChanges" :disabled="!hasChanged">Save Changes</b-button>
    </div>

  </div>

</template>

<script>
export default{

  computed:{
    hasChanged(){

      if(this.originalData) return JSON.stringify(this.notification) !== this.originalData;

      return false;
    }
  },

  mounted(){

    this.getNotificationSettings();
    this.originalData = JSON.stringify(this.notification);


  },
  methods:{
    getNotificationSettings(){

      this.axios.get('/options/global_notification').then(res=>{
        if(res.data){
          this.notification = {...res.data.value};
          this.originalData = JSON.stringify(this.notification);
        }
      })

    },
    saveChanges(){

      this.axios.post('/options', {key: 'global_notification', value: this.notification, is_private: false}).then(res=>{


        if(res.data){
          this.notification = {...res.data.value};
          this.originalData = JSON.stringify(this.notification);
        }
        console.log(res);
      }).catch(e=>{
        console.log(e);

      })

    }
  },
  data(){
    return{
      notification:{
        show: false,
        text: ''
      },
      originalData: null
    }
  },


}
</script>