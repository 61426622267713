
<template>

  <div v-if="user" id="single-user">

    <div class="container-large">
      <div class="row align-items-center">
        <div class="col-auto">
          <h2> {{user.email}}</h2>
        </div>
        <div class="col-auto ml-auto ext-right">
        </div>
      </div>
    </div>

    <div class="container-large mt-4">

      <div class="row">

        <div class="col-12 col-md-6">

          <div class="details mb-4">
            <div class="section-toggle mb-3 d-flex align-items-center" @click="toggle.details = !toggle.details">
              <h6 class="font-weight-bold mb-0 " >Λεπτομέρειες</h6>
              <span class="ml-auto" :class="toggle.details ? 'icon-chevron-down' : 'icon-chevron-right'"></span>
            </div>


            <div v-show="toggle.details">
              <table  class="w-100 b mt-3">
                <tbody>
                <tr v-for="field in fields" :key="field.key" class="user-detail-item">
                  <td class="py-1 font-weight-light title-label" style="width:35%">{{ field.title}}</td>
                  <td class="  font-weight-bold pr-5 title" style="width:65%">

                  <span v-if="field.key === 'roles'">

                     <b-badge :variant="getVariant">{{getRole}}</b-badge>
                  </span>
                    <span v-else>
                 {{ user[field.key] || '-'}}
                  </span></td>
                </tr>
                </tbody>
              </table>
            </div>




          </div>

          <div v-if="user.roles && user.roles.includes('PRESCRIBER')" class="rates mb-4">
            <div class="section-toggle mb-3 d-flex align-items-center" @click="toggle.rates = !toggle.rates">
              <h6 class="font-weight-bold mb-0 ">Ποσοστό επιστροφής (ανά περίοδο)</h6>
              <span class="ml-auto" :class="toggle.rates ? 'icon-chevron-down' : 'icon-chevron-right'"></span>
            </div>

            <div v-show="toggle.rates">
              <b-button size="sm" variant="primary" @click="$refs['prescription-rate-modal'].show();" class="my-3 ml-auto">Προσθήκη νέου</b-button>
              <div v-for="(rate,index) in user.rates" :key="rate.id" class="p-2" :class="index % 2 === 0 ? 'bg-light' : ''">

                <div class="row align-items-center px-3">

                  <div class="col-6">
                    <span class="font-weight-light title-label">Percentage Rate</span><br>
                    <span class="">{{rate.return_rate}}%</span>
                  </div>
                  <div class="col-6">
                    <span class="font-weight-light title-label">Valid From</span><br>
                    <span class="">{{rate.month >= 10 ? rate.month : '0' + rate.month}}/{{rate.year}}</span>
                  </div>
                </div>
              </div>


            </div>
          </div>
          <div class="details mb-4">
            <div class="section-toggle mb-3 d-flex align-items-center" @click="toggle.orders = !toggle.orders">
              <h6 class="font-weight-bold mb-0 " >Ιστορικό παραγγελιών</h6>
              <span class="ml-auto" :class="toggle.orders ? 'icon-chevron-down' : 'icon-chevron-right'"></span>
            </div>


            <div v-show="toggle.orders" style="max-height:350px;overflow-y:auto;">

              <div v-if="loaded && userOrders.length === 0 ">
                Δεν έχουν γίνει παραγγελίες από τον χρήστη.
              </div>
              <div v-for="(order,index) in userOrders" :key="order.id" class="p-2" :class="index % 2 === 0 ? 'bg-light' : ''">

                <div class="row align-items-center">
                  <div class="col-6 col-md-3 px-3">
                    <span class="font-weight-light title-label">Order Id</span><br>
                    <span class="">Order #{{order.id}}</span>
                  </div>
                  <div class="col-6 col-md-3 px-3">
                    <span class="font-weight-light title-label">Total</span><br>
                    <span class="font-weight-bold">{{order.total}}€</span>
                  </div>

                  <div class="col-6 col-md-3 px-3">
                    <span class="font-weight-light title-label">Status</span><br>

                    <b-badge :style="`background-color:${$store.state.status_colors[order.status]}`">
                      <span style="font-size:0.6rem">{{$store.getters.getStatus(order.status)}}</span>
                    </b-badge>

                  </div>
                  <div class="col-6 col-md-3">
                    <b-button size="xs" variant="link" style="color:#000;" @click="openOrder(order.id)">
                      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="black" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" class="feather feather-external-link"><path d="M18 13v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h6"></path><polyline points="15 3 21 3 21 9"></polyline><line x1="10" y1="14" x2="21" y2="3"></line>
                      </svg>
                    </b-button>
                  </div>



                </div>

              </div>
            </div>


          </div>
        </div>
        <div class="col-12 col-md-6">
          <div class="actions mb-4 px-3">
            <div class="section-toggle mb-3 d-flex align-items-center" @click="toggle.actions = !toggle.actions">
              <h6 class="font-weight-bold mb-0 " >Ενέργειες</h6>
              <span class="ml-auto" :class="toggle.actions ? 'icon-chevron-down' : 'icon-chevron-right'"></span>
            </div>

            <div v-show="toggle.actions">

              <b-button size="xs" v-if="!user.roles || !user.roles.includes('PRESCRIBER')" variant="primary" @click="makePrescriber">
                <span class="title-label">(user can prescribe supplements)</span><br>
                Mark as prescriber </b-button>
            </div>
          </div>
          <div class="details mb-4 px-3">

          </div>
          <div v-if="user.roles.includes('PRESCRIBER')">
            <div class="actions mb-4 px-3">
              <div class="section-toggle mb-3 d-flex align-items-center" @click="toggle.returns = !toggle.returns">
                <h6 class="font-weight-bold mb-0 " >Επιστροφές / μήνα / έτος</h6>
                <span class="ml-auto" :class="toggle.returns ? 'icon-chevron-down' : 'icon-chevron-right'"></span>
              </div>

              <div v-show="toggle.returns">
                <label>Επιλογή έτους
                <b-form-select v-model="analyticsYear" :options="years" class="mt-3" @change="getPrescriberReturns"></b-form-select>
                </label>


                <div >


                  <table class="table-bordered table-striped  w-100">
                    <tr>
                      <th class="w-50 px-3 py-2">Μήνας</th>
                      <th class="w-50 px-3 py-2">Ποσό επιστροφής</th>
                    </tr>
                    <tr v-for="(month,index) in months" :key="month">
                      <td class="px-3 py-1">         {{month}}</td>
                      <td class="px-3">          {{formatReturns(index) ? formatReturns(index).sum.toFixed(2) + '€' : '-' }}</td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>
            <div class="details mb-4 px-3">

            </div>

          </div>

        </div>

      </div>




      <b-modal ref="prescription-rate-modal" id="prescription-rate-modal" title="Create new rate"  @ok="createRate">

        <div class="row">

          <div class="col-12">
            <b-form-input type="number" v-model="newRate.rate" placeholder="New rate (e.g. 15)"/>

            <b-form-select v-model="newRate.month" :options="months" class="mt-3"></b-form-select>

            <b-form-select v-model="newRate.year" :options="years" class="mt-3"></b-form-select>
          </div>
        </div>

      </b-modal>
    </div>


  </div>
</template>
<style lang="scss">
.stock-notice{
  list-style-type:circle;
  padding-left:20px;
}
.user-detail-item{
  &:nth-child(even){
    background-color:$primary_light;
  }
  .title-label{
    padding-left:0.5rem;
    font-size:13px;
  }
  .title{
    font-size:14px;
  }
}
.user-item-wrap{
  buser:1px solid $primary_shade;
  margin-top:-1px;

  .title-label{
    font-size:9px;
  }
  .title{
    font-size:14px;
  }
  &:first-child{
    buser-top-left-radius: 12px!important;
    buser-top-right-radius: 12px!important;
  }
  &:last-child{
    buser-bottom-left-radius: 12px;
    buser-bottom-right-radius: 12px;
  }
  &:nth-child(even){
    background-color:$primary_light;
  }
}
.section-toggle{
  cursor:pointer;
}
</style>

<script>

import {mapState} from "vuex";

export default{

  computed:{
    ...mapState({
      statuses: state => state.statuses,
      status_colors: state => state.status_colors
    }),
    getRole(){
      if(this.user.roles.includes('ADMIN')) return 'Admin';
      if(this.user.roles.includes('PRESCRIBER')) return 'Prescriber'
      else return 'Customer'
    },
    getVariant(){
      if(this.user.roles.includes('ADMIN')) return 'primary';
      if(this.user.roles.includes('PRESCRIBER')) return 'success'
      else return 'info'
    }
  },
  data(){
    return{
      user: null,
      analyticsYear: new Date().getFullYear(),
      returns: [],
      loaded: false,
      fields: [{title: 'User ID', key: 'id'},
        {title: 'Ρόλοι', key: 'roles'},
        {title: 'Όνομα', key: 'firstName'}, {title: 'Επώνυμο', key: 'lastName'}, {title: 'E-mail', key: 'email'},
        {title: 'Κουπόνι', key: 'dietitian_coupon'}],

      orderFields:  [
          {title: 'Order Id', key: 'id'},
      {title: 'Status', key: 'status'},
      {title: 'Total', key: 'total'},
        {title: 'Discount', key: 'discount'}],

      newRate: {
        rate: null,
        year: new Date().getFullYear(),
        month: new Date().getMonth() + 2
      },

      userOrders: [],
      toggle:{
        details: true,
        actions: true,
        orders: true,
        rates: true,
        returns: true
      },
    }
  },
  mounted(){
    this.getUser();

  },
  methods:{

    formatReturns(month){
      return this.returns.find(x =>{
        return x.month === (parseInt(month) +1) && x.status === 'COMPLETED'
      })
    },
    getUser(){
      this.axios.get('/admin/users/'+this.$route.params.userId).then(res=>{
        this.user = res.data;
        this.getPrescriberReturns();
        this.getUsersOrders();
      }).catch(()=>{
        this.$notify({type: 'error', title:'Something went wrong', text: 'Please try again later!'});
      })
    },


    makePrescriber(){
      this.axios.post('/admin/users/'+this.$route.params.userId +'/make-prescriber').then(()=>{
        this.getUser();
      }).catch(()=>{
        this.$notify({type: 'error', title:'Something went wrong', text: 'Please try again later!'});
      })
    },

    getUsersOrders(){
      this.axios.get('/admin/orders', {params: {search: this.user.email, limit: 1000}}).then(res=>{
        this.userOrders = res.data.rows;
        this.loaded = true;
      }).catch(()=>{
        this.$notify({type: 'error', title:'Something went wrong', text: 'Please try again later!'});
      })
    },

    getPrescriberReturns(){
      if(!this.user.roles.includes('PRESCRIBER')) return;
      this.axios.get('/admin/users/'+this.$route.params.userId +'/returns?year='+this.analyticsYear).then((res)=>{
        this.returns = res.data;
      }).catch(()=>{
        this.$notify({type: 'error', title:'Something went wrong', text: 'Please try again later!'});
      })
    },
    openOrder(orderId){
      window.open('/orders/'+orderId, '_blank')
    },

    createRate(){

      let newRate = {
        rate: parseFloat(this.newRate.rate),
        year: this.newRate.year,
        month: parseInt(this.newRate.month) + 1
      }

      this.axios.post('/admin/users/'+this.$route.params.userId +'/update-percentage-rate', newRate).then(()=>{
        this.getUser();
      }).catch(()=>{
        this.$notify({type: 'error', title:'Something went wrong', text: 'Please try again later!'});
      });
    }

  }
}
</script>