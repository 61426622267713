
<template>
  <div :style="`border-radius:12px;background-color:#F0F4F9;background-image:url('${url}');
                background-repeat:no-repeat;background-size:cover;height:${height}px;width:${width}px`"/>
</template>

<script>

export default{
  props:{
    url: String,
    width: String,
    height: String
  }
}
</script>