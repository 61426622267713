<template>
  <div v-if="page" class="page">


    <div class="container">
      <div class="row">
        <div class="col-12 col-md-6 mt-3">
          <label>Page name</label>
          <b-form-input v-model="page.title" placeholder="Product name" @change="slugify"/>
        </div>
        <div class="col-12 col-md-6 mt-3">
          <label>Page slug</label>
          <b-form-input v-model="page.slug" placeholder="Slug (url link)"/>
        </div>

        <div class="col-12 mt-3">

            <label>Page Content</label>
            <wysiwyg v-model="page.content" class="w-100"></wysiwyg>
        </div>

        <div class="col-12 mt-3 text-right">
          <b-button variant="success" @click="saveChanges">{{ 'Save changes' }}</b-button>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
export default{

  data(){
    return{
      page: null
    }
  },
  mounted(){
    this.getPage();
  },
  methods:{
    getPage(){
      this.axios.get('/pages/slug/?slug=' + this.$route.params.slug).then(res=>{
        this.page = res.data;
      }).catch(()=>{
        this.$notify({type: 'error', title:'Something went wrong', text: 'Please try again later!'});
      })
    },
    slugify(){
      if(this.isEditMode) return;
      this.page.slug = this.slugFromName(this.page.title);
      // To do: check availability on server!
    },

    saveChanges(){
      console.log(this.page);
      this.axios.put('/pages/' + this.page.id, {page: this.page}).then(()=>{
        this.$notify({type: 'success', title:'Success', text: 'The page was updated successfully.'});
        this.redirectTo('pages');

      }).catch(()=>{
        this.$notify({type: 'error', title:'Something went wrong', text: 'Please try again later!'});
      })
    }

  }
}
</script>