<template>

  <div id="analytics">
    <div class="container-large">
      <div class="row">
        <div class="col-12">
          <h2>Πωλήσεις</h2>
        </div>

      </div>


    </div>

    <div class="container-large py-4">

      <div class="row">
        <div class="col-12">
          <label>Επιλογή έτους
            <b-form-select v-model="year" :options="years" class="mt-3" @change="getMonthlySales"></b-form-select>
          </label>
        </div>

        <div class="col-12">
          <div class="row">
            <div v-for="(item,index) in analytics" :key="`${year}-${index}`" class="col-6 col-md-4 my-2">
              <b-card class="h-100">
                <b-card-title style="font-size:14px;">{{item.title}}</b-card-title>
                <h3>{{item.calc()}} {{item.sign}}</h3>

              </b-card>
            </div>
          </div>
        </div>
        <div class="col-12">


          <Bar
              :key="chartKey"
              :chart-options="chartOptions"
              :chart-data="chartData"
              chart-id="sales-chart"
              :dataset-id-key="''+chartKey"
              :height="350"
          />
        </div>
      </div>

    </div>
  </div>

</template>

<script>
import { Bar } from 'vue-chartjs/legacy'

import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale
} from 'chart.js'

ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale)


export default{
components:{Bar},
  created(){
    this.getMonthlySales();
    this.chartData.labels = Object.values(this.months);
  },
  data(){
    return{
      year: new Date().getFullYear(),
      salesData: [],

      chartKey: 0,
      chartData: {
        labels: [],
        datasets: [
          {
            label: 'Πωλήσεις',
            backgroundColor: '#2f54a5',
            data: []
          }
        ]
      },
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false
      },
      analytics: [
        {
          title: 'Σύνολο παραγγελιών',
          calc: () => {
            return this.mapCount('order_count');
          }
        },
        {
          title: 'Σύνολο πωλήσεων',
          calc: () => {
            return this.mapCount('subtotal');
          },
          sign: '€'
        },
        {
          title: 'Σύνολο (χωρίς έξοδα αποστολής, εκπτώσεις κ.λπ.)',
          calc: () => {
            return this.mapCount('total');
          },
          sign: '€'
        },
        {
          title: 'Έξοδα αποστολής',

          calc: () => {
            return this.mapCount('shipping_fees')
          },
          sign: '€'
        },
        {
          title: 'Έξοδα αντικαταβολής',
          calc: () => {
            return this.mapCount('payment_fees')
          },
          sign: '€'
        },
        {
          title: 'Εκπτώσεις',
          calc: () => {
            return this.mapCount('discounts')
          },
          sign: '€'
        },
      ]
    }
  },

  methods:{

    mapCount(key){
      return +this.salesData.map(x => x[key]).reduce((a, b) => {

        let aFixed = parseFloat(a) || 0
        let bFixed = parseFloat(b) || 0
        return aFixed + bFixed;
      }, 0).toFixed(2);
    },
    getMonthlySales(){

      this.$nextTick(()=>{
        this.axios.get('/admin/analytics/sales?year='+this.year).then((res)=>{
          this.salesData = res.data;
          this.chartData.datasets[0].data = [];
          this.setupDataset();
        }).catch((e)=>{
          console.log(e);
          this.$notify({type: 'error', title:'Something went wrong', text: 'Please try again later!'});
        })
      })

    },

    setupDataset(){

      // let datasets = [{
      //   title: 'Πωλήσεις',
      //   backgroundColor: '#2f54a5',
      //   data: []
      // }];
      for(let month of Object.keys(this.months)){

        let data = this.salesData.find(x => (x.month-1) === parseInt(month));
        this.chartData.datasets[0].data.push(data ? data.subtotal : 0)
      }

      // this.chartData.datasets = datasets;
      this.chartKey++;
    }
  }

}
</script>