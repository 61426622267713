<template>

  <div id="product-details">

    <div class="container-large row mb-4">
      <div class="col-auto">
        <h3>{{ isEditMode? 'Edit Product - ' + product.name : 'New Product' }}</h3>
      </div>

    </div>

    <div class=" container-large row">
      <div class="col-12 col-md-9">
        <div class=" p-2 rounded">
          <b-tabs fill >
            <b-tab title="Details">
              <div class="row mt-5">
                <div class="col-12 text-left">
                  <label>Featured Image</label>
                  <div v-if="data.featured_image">
                    <image-preview width="120" height="120" :url="data.featured_image"/>
                    <b-button variant="link" @click="editFeaturedImage = !editFeaturedImage">change image</b-button>

                  </div>
                  <upload-area v-if="editFeaturedImage || !data.featured_image" :max-files="1" @addedImage="addedFeaturedImage" />
                </div>


                <div class="col-12 col-md-6">
                  <label>Product name</label>
                  <b-form-input v-model="data.name" placeholder="Product name" @change="slugify"/>
                </div>
                <div class="col-12 col-md-6">
                  <label>Product slug</label>
                  <b-form-input v-model="data.slug"  :disabled="isEditMode"  placeholder="Slug (url link)"/>
                </div>

                <div class="col-12 text-left">
                  <label>Gallery Images</label>
                  <b-button variant="link" @click="addGalleryImages = !addGalleryImages">{{ addGalleryImages ? 'Close image upload' : 'Add images' }}</b-button>
                  <br>

                  <draggable v-model="data.gallery_images" @change="saveImageReOrdering">
                    <div v-for="(image, index) in data.gallery_images" :key="'image-'+index" class="d-inline-block position-relative" style="cursor:move">
                      <image-preview width="120" height="120" :url="image" class="mr-2"/>
                      <b-button variant="danger" class="px-2 py-0 m-0 position-absolute " style="bottom:10px;right:10px;"
                                @click="removeImage(image)"
                      ><span class="icon-trash" style="font-size:0.7rem;"></span></b-button>
                    </div>
                  </draggable>

                  <upload-area v-if="addGalleryImages" :max-files="10" @addedImage="addedGalleryImage" />
                </div>
              </div>
            </b-tab>

            <b-tab title="Content">

              <div class="row mt-5">
                <div class="col-12 col-md-6 text-left">
                  <label>Short Description</label>

                  <ckeditor :editor="editor" v-model="data.short_description" :config="editorConfig"></ckeditor>
                </div>
                <div class="col-12 col-md-6 text-left">
                  <label>Description</label>
                  <ckeditor :editor="editor" v-model="data.description" :config="editorConfig"></ckeditor>
                </div>

                <div class="col-12 col-md-6 text-left">
                  <label>Suggested For</label>
                  <ckeditor :editor="editor" v-model="data.suggested_for" :config="editorConfig"></ckeditor>
                </div>

                <div class="col-12 col-md-6 text-left">
                  <label>Nutrition Label</label>
                  <ckeditor :editor="editor" v-model="data.label" :config="editorConfig"></ckeditor>
                </div>

                <div class="col-12 col-md-6 text-left">
                  <label>Proposed Dosage</label>
                  <ckeditor :editor="editor" v-model="data.dosage" :config="editorConfig"></ckeditor>
                </div>

              </div>

            </b-tab>

            <b-tab title="Meta Data">
              <div class="row mt-4">
                <div class="col-12">


                  <div class="row">
                    <div class="col-12 col-md-6">

                      <label>EOF Code</label>
                      <b-form-input type="text" v-model="data.metadata.eofCode" placeholder="EOF Code"/>
                    </div>

                    <div class="col-12 col-md-6">

                      <label>Shape</label>
                      <b-form-input type="text" v-model="data.metadata.shape" placeholder="Shape (e.g. 60 capsules)"/>
                    </div>


                    <div class="col-12">

                      <label>Icons</label>

                      <multiselect v-model="data.metadata.icons" :options="icons" :custom-label="multiSelect"
                                  :multiple="true"
                                   :close-on-select="false"
                                   :clear-on-select="false"
                                   placeholder="Select one" label="name" track-by="value"></multiselect>

                    </div>


                  </div>



                </div>

              </div>
            </b-tab>

            <b-tab title="Pricing">
              <div class="row mt-5">

                <div class="col-12 col-md-6">
                  <label>Product Price (without VAT)</label>

                  <b-form-input type="number" v-model="data.price" placeholder="Product price (excluding VAT)"/>

                </div>
                <div class="col-12 col-md-6">
                  <label>Product B2B Price (without VAT)</label>
                  <b-form-input type="number" v-model="data.b2b_price" placeholder="Product B2B price (cost of unit) "/>
                </div>
                <div class="col-12 col-md-6">
                  <label>Product Sale Price (without VAT) <b-badge v-if="data.sale_price" variant="success">sale percentage {{calculatePercentage()}}%</b-badge></label>

                  <b-form-input type="number" v-model="data.sale_price" placeholder="Product price (excluding VAT)"/>

                </div>
              </div>
            </b-tab>

            <b-tab title="Warehouse">
              <div class="row mt-5">

                <div class="col-12 col-md-6">
                  <label>Stock Quantity</label>

                  <b-form-input type="number" v-model="data.stock_quantity" :disabled="isEditMode" placeholder="Stock Quantity"/>
                </div>

                <div class="col-12 col-md-6">
                  <label>Barcode number</label>

                  <b-form-input v-model="data.barcode" placeholder="Barcode (e.g. 21421414)"/>
                </div>

              </div>
            </b-tab>

          </b-tabs>
        </div>

      </div>
      <div class="col-12 col-md-3">
        <div class="my-3">
          <b-button v-if="isEditMode" variant="danger" class="mr-2 p-1" size="xs" @click="deleteProduct">Delete</b-button>
          <b-button :variant="isEditMode ? 'success' :'primary'" :disabled="!canCreateOrUpdate" @click="createProduct">{{ isEditMode ? 'Save changes': 'Create product' }}</b-button>

        </div>

        <div v-if="product" class="my-3 text-center py-4 " style="background-color:#eeeeee;">

          <div v-if="data.publish_status === 'draft'">This product is draft and is not displaying in the store!</div>
          <div v-if="data.publish_status === 'live'">This product is visible in the store!</div>
          <b-button :disabled="data.publish_status === 'draft'" class="text-right my-3" variant="link"
                    @click="publishProduct('draft')">Make Draft</b-button>
          <b-button :disabled="data.publish_status === 'live'" class="text-right my-3" variant="primary"
                    @click="publishProduct('live')">Publish</b-button>
        </div>


      </div>
    </div>

  </div>
</template>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang="scss">
@import "~vue-wysiwyg/dist/vueWysiwyg.css";

#product-details{
  .col-12{
    margin-bottom:1rem;
  }
}
</style>
<script>
import draggable from "vuedraggable"
import upload from "@/components/upload";
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

import Multiselect from 'vue-multiselect'
// import {SimpleUploadAdapter} from "@ckeditor/ckeditor5-upload";
export default{
  props:{
    product: Object
  },
  components: {uploadArea: upload, draggable, Multiselect},

  computed:{
    canCreateOrUpdate(){

      let canUpdate = this.data.name && this.data.slug && this.data.price && this.data.b2b_price && this.data.stock_quantity;

      // we also check if we changed any data!
      if(this.isEditMode) canUpdate = canUpdate && this.hasChangedData

      return canUpdate;
    }
  },
  watch:{
    data: {
      handler(){
        if(!this.isLoaded) return;
        this.hasChangedData = true;
      },
      deep: true
    },
  },
  data(){
    return{
      editor: ClassicEditor,
      editorConfig: {
        // plugins: [SimpleUploadAdapter],
        simpleUpload: {
          uploadUrl:  process.env.VUE_APP_API_URL + '/general/image-upload',

        }
        // The configuration of the editor.
      },
      isEditMode: false,
      hasChangedData: false,
      editFeaturedImage: false,
      addGalleryImages: false,
      isLoaded: false,
      data: {
        publish_status: 'draft',
        name: '',
        slug: '',
        description: null,
        short_description: null,
        featured_image: null,
        gallery_images: [],
        b2b_price: null,
        price: null,
        sale_price: null,
        sale_price_end_date: null,
        stock_quantity: null,
        barcode: null,
        metadata:{
          eofCode: null,
          shape: null,
          icons: []
        }
      },
      icons: [
        {text: 'European Union', value: 'europe.png'},
        {text: 'GMP Certified', value: 'gmp.png'},
        {text: 'HACCP Certified', value: 'haccp.png'},
        {text: 'NON GMO', value: 'non-gmo.png'},
        {text: 'Sugar Free', value: 'sugar-free.png'},
        {text: 'Vegan', value: 'vegan.png'},
        {text: 'Vegetarian', value: 'vegetarian.png'},
        {text: 'Wheat Free', value: 'wheat.png'},

      ]
    }
  },

  mounted(){
    if(this.product){
      this.data = {...this.product};
      this.isEditMode = true;
      this.$nextTick(()=>{
        this.isLoaded= true;
      })
    }
  },

  methods:{
    slugify(){
      if(this.isEditMode) return;
      this.data.slug = this.slugFromName(this.data.name);
      // To do: check availability on server!
    },

    addedFeaturedImage(url){
      this.data.featured_image = url;
    },
    addedGalleryImage(url){
      this.data.gallery_images.push(url);
    },
    createProduct(){

      if(this.isEditMode) return this.updateProduct();
      this.axios.post('/admin/products', {product: this.data}).then(()=>{
        this.$notify({type: 'success', title:'Success', text: 'The product was created successfully.'});
        this.redirectTo('products');

      }).catch(()=>{
        this.$notify({type: 'error', title:'Something went wrong', text: 'Please make sure you have filled all the fields accordingly'});
      })
    },

    removeImage(image){
      this.data.gallery_images = this.data.gallery_images.filter( x=> x!==image);
    },

    updateProduct(){
      if(!this.product.id) return;
      this.axios.put('/admin/products/'+this.product.id, {product: this.data}).then((res)=>{
        this.$notify({type: 'success', title:'Success', text: 'The product was updated successfully.'});
        this.$emit('productUpdated', res.data);
        this.hasChangedData = false;
      }).catch(()=>{
        this.$notify({type: 'error', title:'Something went wrong', text: 'Failed to update the product'});

      })
    },

    saveImageReOrdering(){
      if(this.isEditMode) this.updateProduct();
    },

    calculatePercentage(){

      let discounted = parseFloat(this.data.sale_price);
      let original = parseFloat(this.data.price);

      return +(( (original - discounted) / original) * 100).toFixed(0)
    },

    publishProduct(status){
      this.data.publish_status = status;
      this.updateProduct();
    },
    deleteProduct(){
      this.$swal({
        title: 'Are you sure you want to delete this product?',
        text: "You won't be able to revert this!",
        showCancelButton: true,
        icon: 'warning',
        confirmButtonText: 'Yes, delete it!',
        denyButtonText: 'Do not delete it!'
      }).then(res =>{
        if(res.isConfirmed){
          this.axios.delete('/admin/products/'+this.product.id).then(()=>{
            this.$notify({type: 'success', title:'Success', text: 'The product was deleted successfully.'});
            this.redirectTo('products');
          }).catch(()=>{})
        }
      })
    },

    multiSelect ({ text }) {
      return `${text}`
    }
  }
}
</script>