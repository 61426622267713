<template>
  <div id="app">

    <auth v-if="$route.meta && $route.meta.authPage"/>
    <default-layout v-else/>

    <notifications position="bottom center"/>
  </div>
</template>

<script>
import auth from '@/layouts/auth'
import defaultLayout from '@/layouts/default'
export default {
  name: 'App',
  components: {
    auth,
    defaultLayout
  }
}
</script>

<style lang="scss">
#app {
  min-height:100%;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  .router-view-area{
    min-height:70vh;
  }
}
</style>
