import Vue from 'vue'
import App from './App.vue'
import {BootstrapVue} from "bootstrap-vue";
import router from "./router";
import store from "./store";
import axios from 'axios'
import VueAxios from "vue-axios";
import '@/assets/styles/globals/_boostrap.scss';
import Notifications from 'vue-notification'
import {setupAxiosInterceptors} from './store/interceptors';
Vue.config.productionTip = false
import defaultMixin from "./mixins/default"
import ProductImagePreview from "@/components/ProductImagePreview";
import wysiwyg from "vue-wysiwyg";
import CKEditor from '@ckeditor/ckeditor5-vue2';

import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';

Vue.component('date-picker', DatePicker);
import VueSweetalert2 from 'vue-sweetalert2';
// If you don't need the styles, do not connect
import 'sweetalert2/dist/sweetalert2.min.css';

import VueGoodTablePlugin from 'vue-good-table';

// import the styles
import 'vue-good-table/dist/vue-good-table.css'

Vue.use(VueGoodTablePlugin);


Vue.use(VueSweetalert2, {
  confirmButtonColor: '#2f54a5',
});

Vue.component('image-preview', ProductImagePreview);
Vue.use(wysiwyg, {
  hideModules: {
    image: true
  }
}); // config is optional. more below
Vue.use( CKEditor );

Vue.use(BootstrapVue);

const axiosInstance = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
});

setupAxiosInterceptors(store, router, axiosInstance);

Vue.use(Notifications)

Vue.use(VueAxios, axiosInstance);

Vue.mixin(defaultMixin);

new Vue({
  render: h => h(App),
  store: store,
  router: router,
  created(){
    store.dispatch('init');
  }
}).$mount('#app')
