<template>

  <div id="users">

    <div class="container-large">
      <div class="row align-items-center">
        <div class="col-auto">
          <h2>Χρήστες</h2>
        </div>
        <div class="col-auto ml-auto text-right">

          <b-button size="sm" variant="link" @click="$refs['import-users'].show()">Μαζική εισαγωγή από CSV</b-button>

        </div>
      </div>
    </div>

    <div class="container-large my-3">

      <div class="row">

        <div class="col-12 col-md-4">
          <b-form-input v-model="query.search" debounce="500" placeholder="Αναζήτηση χρηστών..." class="py-2"></b-form-input>
        </div>
      </div>
    </div>

    <div class="container-large">
      <div class="d-flex align-items-center filters-wrap mt-4">

        <div v-for="item in user_roles" :key="item.title" @click="query.role = item.role" class="status-filter mb-4 flex-shrink-0 cursor-pointer mr-3"
        :class="item.role === query.role ? 'active' : ''">
          {{item.title}}
        </div>

      </div>
    </div>


    <div class="container-large  mt-4">
      <div class="row">
        <div class="col-12">

          <vue-good-table
              ref="orders-table"
              mode="remote"
              :pagination-options="{
                enabled: true,
                total: totalUsers,
                setCurrentPage: this.query.page,
                dropdownAllowAll: false,
                perPage: this.query.limit,
                rowsPerPageLabel: 'Ανά σελίδα',
                nextLabel:'Επόμενη',
                prevLabel: 'Προηγούμενη',
                perPageDropdown: [10, 15, 20, 25],
              }"
              :totalRows="totalUsers"
              :sort-options="{ enabled: false }"
              style-class="vgt-table striped condensed"
              theme="polar-bear"
              @on-page-change="params => this.query.page = params.currentPage"
              @on-row-click="params => redirectTo('users-slug', {userId: params.row.id})"
              @on-per-page-change="params => this.query.limit = params.currentPerPage"
              :columns="columns"
              :rows="users"
          >
            <template slot="table-row" slot-scope="props">
              <div v-if="props.column.field === 'fullName'">
                {{props.row.firstName}} {{props.row.lastName}}
              </div>
              <div v-else-if="props.column.field === 'role'">
                <b-badge :variant="getVariant(props.row)">{{getRole(props.row)}}</b-badge>
              </div>
              <div v-else>

                {{props.formattedRow[props.column.field]}}
              </div>
            </template>

            <div slot="emptystate">
              Δεν βρέθηκαν χρήστες
            </div>
          </vue-good-table>
        </div>

      </div>
    </div>


    <b-modal ref="import-users" id="import-users" title="Import users" hide-footer>
      <upload :max-files="1" :url="getUploadCsvUrl" @addedImage="$refs['import-users'].hide()"/>


    </b-modal>
  </div>

</template>
<style lang="scss">
.filters-wrap{
  overflow-y: auto;
  > div {
    font-size:14px;
  }
}
.status-filter{
  border-bottom:1px solid;
  border-color:transparent;
  &.active{
    font-weight:bold;
    border-bottom:1px solid;
    border-color: $primary;
  }
}
.user-item{
  cursor:pointer;
  margin-top:2px;
  &:nth-child(even){
    background-color:$primary_shade;
  }
  border-color:$primary_shade;
  &:hover{
    background-color:$primary_shade;
  }
  > div{
    font-size:13px;
    font-weight:500;
  }
}
</style>
<script>
import {mapState} from "vuex";
import upload from "@/components/upload";

export default{

  data(){
    return{
      users: [],
      totalUsers: 0,
      query:{
        limit: 10,
        page: 1,
        search: '',
        role: null
      },
      loaded: false,
      columns: [
        {label: 'Ρόλος', field: 'role'},
        {label: 'Ονοματεπώνυμο', field: 'fullName'},
        {label: 'E-mail', field: 'email'},
        {
          label: 'Κωδικός κουπονιού', field: 'dietitian_coupon'
        }
      ]
    }
  },
  components: {upload},
  computed:{
    ...mapState({
      user_roles: state => state.user_roles,
    }),
    getUploadCsvUrl(){
      return process.env.VUE_APP_API_URL + '/admin/users/import-users';
    }
  },

  watch:{

    'query.page'(val){
      if(!this.loaded) return;
      this.fixUrlQuery('page', val);
      this.getUsers();
    },

    'query.role'(val){
      if(!this.loaded) return;
      this.query.page = 1;
      this.fixUrlQuery('role', val);
      this.getUsers()
    },

    'query.limit'(val){
      if(!this.loaded) return;
      this.query.page = 1;
      this.fixUrlQuery('limit', val);
      this.getUsers()
    },
    'query.search'(val){
      if(!this.loaded) return;
      this.query.page = 1;
      this.fixUrlQuery('search', val);
      this.getUsers()
    }

  },

  mounted(){


    if(this.$route.query.page) this.query.page = this.$route.query.page
    if(this.$route.query.search) this.query.search = this.$route.query.search;
    if(this.$route.query.role) this.query.role = this.$route.query.role;


    this.getUsers()
  },

  methods:{

    fixUrlQuery(key, value){
      let query = {...this.$route.query}

      if(!value) delete query[key]
      else query[key] = value;
      this.$router.push({query})
    },
    getUsers(){

      let query = {...this.query};

      query.offset = (query.page-1) * query.limit

      this.axios.get('/admin/users', {params: query}).then(res=>{
        this.users = res.data.rows;
        this.totalUsers = res.data.count;
        this.loaded = true;
      }).catch(()=>{
        this.$notify({type: 'error', title:'Something went wrong', text: 'Please try again later!'});
      })
    },

    getRole(user){
      if(user.roles.includes('ADMIN')) return 'Admin';
      if(user.roles.includes('PRESCRIBER')) return 'Prescriber'
      else return 'Customer'
    },
    getVariant(user){
      if(user.roles.includes('ADMIN')) return 'primary';
      if(user.roles.includes('PRESCRIBER')) return 'success'
      else return 'info'
    }
  }

}
</script>