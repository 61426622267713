
<template>

  <div v-if="order" id="single-order">

    <div class="container-large">
      <div class="row align-items-center">
        <div class="col-auto">
          <h2>Order #{{order.id}}</h2>
        </div>
        <div class="col-auto ml-auto ext-right">
        </div>
      </div>
    </div>

    <div class="container-large mt-4">

      <div class="row">

        <div class="col-12 col-md-6">

          <div class="details mb-4">
            <div class="section-toggle mb-3 d-flex align-items-center" @click="toggle.details = !toggle.details">
              <h6 class="font-weight-bold mb-0 " >Λεπτομέρειες παραγγελίας</h6>
              <span class="ml-auto" :class="toggle.details ? 'icon-chevron-down' : 'icon-chevron-right'"></span>
            </div>


            <div v-show="toggle.details">
              <table  class="w-100 b mt-3">
                <tbody>
                <tr v-for="field in fields" :key="field.key" class="order-detail-item">
                  <td class="py-1 font-weight-light title-label" style="width:35%">{{ field.title}}</td>
                  <td class="  font-weight-bold pr-5 title" style="width:65%">

                    <span v-if="field.formatFn">
                      {{field.formatFn(order[field.key])}}
                    </span>
                  <span v-else-if="field.key === 'status'" :style="`font-weight:bold;color:${status_colors[order.status]};`">

                    {{$store.getters.getStatus(order[field.key])}}
                  </span>
                    <span v-else>
                 {{ order[field.key] || '-'}}
                  </span></td>
                </tr>
                <tr class="order-detail-item">
                  <td class="py-1 font-weight-light title-label" style="width:35%">Κουπόνι έκπτωσης</td>
                  <td class="  font-weight-bold pr-5 title" style="width:65%">
                  {{order.order_coupon ? order.order_coupon.name : '-'}}
                  </td>
                </tr>
                </tbody>
              </table>
            </div>


          </div>

          <div class="shipping-info">
            <div class="section-toggle mb-3 d-flex align-items-center" @click="toggle.shipping_info = !toggle.shipping_info">
              <h6 class="font-weight-bold mb-0 " >Αποστολή παραγγελίας (ACS)</h6>
              <span class="ml-auto" :class="toggle.shipping_info ? 'icon-chevron-down' : 'icon-chevron-right'"></span>
            </div>

            <div v-show="toggle.shipping_info" class="wrapper px-3">


              <div v-if="order.order_shipping"  class="d-flex mb-5">
                <div class="mx-2 flex-shrink-0">
                  <span class="font-weight-light title-label">Voucher</span><br>
                  <span class="font-weight-bold title">{{order.order_shipping.voucher_code}}</span>
                </div>
                <div class="mx-2 flex-shrink-0">
                  <span class="font-weight-light title-label">Actions</span><br>

                  <b-button variant="primary" size="xs"
                  @click="printVoucher">Print</b-button>

                  <b-button v-if="!order.order_shipping.is_shipped" variant="danger" size="xs" class="ml-2"
                            @click="deleteVoucher">Cancel Voucher</b-button>
                </div>
              </div>
              <div v-else>

                <div v-if="order.status === 'PROCESSING' && order.shippingMethod === 'courier'">
                  <b-button variant="primary" size="sm" class="mb-4" @click="createVoucher">Create ACS Voucher</b-button>
                </div>
              </div>
            </div>

          </div>

          <div class="products">
            <div class="section-toggle mb-3 d-flex align-items-center" @click="toggle.products = !toggle.products">
              <h6 class="font-weight-bold mb-0 " >Προϊόντα στην παραγγελία</h6>
              <span class="ml-auto" :class="toggle.products ? 'icon-chevron-down' : 'icon-chevron-right'"></span>
            </div>

            <div v-show="toggle.products" class="wrapper px-3">
              <div v-for="item in order.order_products" :key="item.id" class="row order-item-wrap align-items-center py-2 ">

                <div class="col-12 col-md-2">
                  <image-preview width="60" height="60" :url="item.image"/>
                </div>

                <div class="col-6 col-md-5">
                  <span class="font-weight-light title-label">Product Name</span><br>
                  <span class="font-weight-bold title">{{item.name}}</span>
                </div>
                <div class="col-6 col-md-2">
                  <span class="font-weight-light title-label">Quantity</span><br>
                  <span class="font-weight-bold title">{{item.quantity}}</span>
                </div>
                <div class="col-6 col-md-2">
                  <span class="font-weight-light title-label">Price</span><br>
                  <span class="font-weight-bold title">{{(calculatePrice(item))}}€</span>
                </div>

                <div class="px-4">

                </div>

              </div>

            </div>

          </div>


        </div>
        <div class="col-12 col-md-6">
          <div class="actions mb-4 px-3">
            <div class="section-toggle mb-3 d-flex align-items-center" @click="toggle.actions = !toggle.actions">
              <h6 class="font-weight-bold mb-0 ">Ενέργειες</h6>
              <span class="ml-auto" :class="toggle.actions ? 'icon-chevron-down' : 'icon-chevron-right'"></span>
            </div>

            <div v-show="toggle.actions">

                <p class="mb-3">** Προσοχή. Με κάθε αλλαγή κατάστασης στέλνεται και e-mail στον πελάτη!</p>
                <div v-for="item in statuses" :key="item.status" class="w-100">
                  <b-button v-if="item.status && item.status !== order.status" size="xs" :style="`background-color:${status_colors[item.status]}`"
                            @click="updateStatus(item.status)">
                    Αλλαγή κατάστασης σε {{item.title}}
                  </b-button>
                </div>
            </div>
          </div>
          <div class="details mb-4 px-3">
            <div class="section-toggle mb-3 d-flex align-items-center" @click="toggle.shipping = !toggle.shipping">
              <h6 class="font-weight-bold mb-0 " >Διεύθυνση αποστολής</h6>
              <span class="ml-auto" :class="toggle.shipping ? 'icon-chevron-down' : 'icon-chevron-right'"></span>
            </div>


            <div v-show="toggle.shipping">
              <b-button size="xs" variant="warning" class="ml-2" @click="edit.shipping = !edit.shipping">Επεξεργασία</b-button>
              <table class="w-100 b mt-3">
                <tbody>
                <tr v-for="field in shipping_fields" :key="field.key" class="order-detail-item">
                  <td class="py-1 font-weight-light title-label" style="width:35%">{{ field.title}}</td>
                  <td class="font-weight-bold pr-3 title" style="width:65%">

                    <span v-if="!edit.shipping">{{ order.order_shipping_address[field.key] || '-'}}</span>
                    <b-form-input v-else-if="edit.shipping && !field.nonEditable" type="text" class="my-2" v-model="order.order_shipping_address[field.key]"></b-form-input></td>
                </tr>
                </tbody>
              </table>
              <div v-if="edit.shipping" class="text-right">
                <b-button size="xs" variant="success" class=" mt-3 text-right" @click="updateOrderShippingAddressDetails">Αποθήκευση αλλαγών</b-button>
              </div>

            </div>



          </div>

          <div class="notes mb-4 px-3">
            <div class="section-toggle mb-3 d-flex align-items-center" @click="toggle.notes = !toggle.notes">
              <h6 class="font-weight-bold mb-0 " >Σημειώσεις</h6>
              <span class="ml-auto" :class="toggle.notes ? 'icon-chevron-down' : 'icon-chevron-right'"></span>
            </div>


            <div v-show="toggle.notes" style="max-height:400px;overflow-y:auto">
              <table class="w-100 b mt-3">
                <tbody>
                <tr v-for="note in order.order_notes" :key="note.id" class="order-detail-item ">
                  <td class="py-1 font-weight-light w-25 title-label">{{formatDateWithTime(new Date(note.createdAt))}}</td>
                  <td class="w-75 pr-3 title py-2"><div v-html="note.note"></div></td>


                </tr>
                </tbody>
              </table>
            </div>



          </div>
        </div>
      </div>


    </div>


  </div>
</template>
<style lang="scss">
.stock-notice{
  list-style-type:circle;
  padding-left:20px;
}
.order-detail-item{
  &:nth-child(even){
    background-color:$primary_light;
  }
  .title-label{
    padding-left:0.5rem;
    font-size:13px;
  }
  .title{
    font-size:14px;
  }
}
.order-item-wrap{
  border:1px solid $primary_shade;
  margin-top:-1px;

  .title-label{
    font-size:9px;
  }
  .title{
    font-size:14px;
  }
  &:first-child{
    border-top-left-radius: 12px!important;
    border-top-right-radius: 12px!important;
  }
  &:last-child{
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
  }
  &:nth-child(even){
    background-color:$primary_light;
  }
}
.section-toggle{
  cursor:pointer;
}
</style>

<script>

import {mapState} from "vuex";

export default{

  computed:{
    ...mapState({
      statuses: state => state.statuses,
      status_colors: state => state.status_colors
    })
  },
  data(){
    return{
      order: null,
      fields: [
        {
          title: 'Ημερομηνία', key: 'createdAt', formatFn: val => { return this.formatDateWithTime(new Date(val))}
        },
          {title: 'Order ID', key: 'id'},
        {title: 'Κατάσταση παραγγελίας', key: 'status'},
        {title: 'Συνολικό κόστος', key: 'total', formatFn: (val) => {
            return (val ? val.toFixed(2) + ' €' : '-')
          }},
        {title: 'Τρόπος πληρωμής', key: 'paymentMethod', formatFn: (val) => {
            if(val === 'cod') return 'Αντικαταβολή';
            if(val === 'card') return 'Πληρωμή με πιστωτική / χρεωστική κάρτα';
            if(val==='pay_on_receive') return 'Πληρωμή κατά την παραλαβή από το κατάστημα'
            return '-'
          }},
        {title: 'Κόστος πληρωμής', key: 'paymentFee', formatFn: (val) => {
            return (val ? val.toFixed(2) + ' €' : '-')
          }},
        {title: 'Τρόπος αποστολής', key: 'shippingMethod', formatFn: (val) => {
            if(val === 'courier') return 'Αποστολή με ELTA Courier';
            if(val === 'store_pickup') return 'Παραλαβή από το κατάστημα';
            return '-'
          }},
        {title: 'Κόστος αποστολής', key: 'shippingFee',formatFn: (val) => {
            return (val ? val.toFixed(2) + ' €' : '-')
          }},
        {title: 'Όνομα', key: 'firstName'}, {title: 'Επώνυμο', key: 'lastName'}, {title: 'E-mail', key: 'email'}, {title: 'Τηλέφωνο', key: 'phone'},
        {title: 'Εκπτώσεις', key: 'discount', formatFn: (val) => {
            return (val ? val.toFixed(2) + ' €' : '-')
          }},

      ],
      shipping_fields: [
        {title: 'Διεύθυνση', key: 'address'},
        {title: 'Χώρα', key: 'country', nonEditable: true},
        {title: 'Πόλη', key: 'city'},
        {title: 'ΤΚ', key: 'zipCode'},
        {title: 'Όροφος / Όνομα στο κουδούνι', key: 'door'},
        {title: 'Σημειώσεις', key: 'notes'}
      ],

      toggle:{
        shipping: true,
        details: true,
        products: true,
        actions: false,
        notes: true,
        shipping_info: true,
      },
      edit:{
        shipping: false,
        details: false,
        products: false
      }
    }
  },
  mounted(){
    this.getOrder();

  },
  methods:{

    getOrder(){
      this.axios.get('/admin/orders/'+this.$route.params.orderId).then(res=>{
        this.order = res.data;
      }).catch(()=>{
        this.$notify({type: 'error', title:'Something went wrong', text: 'Please try again later!'});
      })
    },

    updateStatus(status){
      this.axios.put('/admin/orders/'+this.$route.params.orderId + '/status', {status:status}).then(()=>{
        this.getOrder();
      }).catch((res)=>{
        if(res.response.data && res.response.data.code === 50){
          this.$notify({type: 'error', title:'Cannot update the order because some products are out of stock!',
            text: 'Please make sure you have enough stock to finalize the order.'});
        }else{
          this.$notify({type: 'error', title:'Something went wrong', text: 'Please try again later!'});
        }

      });
    },

    calculatePrice(item){

      let tax = item.tax_percentage || 1.24;
      if(item.sale_price){
        return (item.sale_price * tax).toFixed(2)
      }
      return (item.price * tax).toFixed(2)
    },

    updateOrderShippingAddressDetails(){

      this.axios.put('/admin/orders/'+this.$route.params.orderId + '/shipping-address', this.order.order_shipping_address).then(()=>{
        this.getOrder();
        this.edit.shipping = false;
        this.$notify({type: 'success', title:'Success', text: 'Update was completed successfully.'});
      }).catch(()=>{

          this.$notify({type: 'error', title:'Something went wrong', text: 'Please try again later!'});
      });

    },

    printVoucher(){
      let voucher = this.order.order_shipping;
      if(!voucher.voucher_url){
        this.axios.get('/admin/orders/'+ voucher.orderId + '/voucher/print').then(res=> {
          voucher.voucher_url = res.data.url;
          if(res.data.url){
            window.open(res.data.url, "_blank");
          }
        }).catch(e=>{
          console.log(e);
        })
      }else{
        window.open(voucher.voucher_url, "_blank");
      }
    },
    deleteVoucher(){

      this.axios.delete('/admin/orders/voucher/delete/'+this.order.order_shipping.voucher_code).then(()=>{
        this.getOrder();
      }).catch(()=>{
        this.$notify({type: 'error', title:'Something went wrong', text: 'Please try again later!'});
      })


    },

    createVoucher(){
      this.axios.post('/admin/orders/'+ this.order.id + '/voucher').then(()=> {
        this.getOrder();
      }).catch(e=>{
        console.log(e);
      })
    }

  }
}
</script>