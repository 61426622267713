import Vuex from "vuex";
import Vue from "vue";
import axios from "axios";
Vue.use(Vuex);

const store = new Vuex.Store({
    state: {
        isLoggedIn: !!localStorage.getItem('isLoggedIn'),
        user: localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : {},
        token: localStorage.getItem('token')  || null,
        loading: false,
        statuses: [
            {title: 'Προβολή όλων', status: null},
            {title: 'Σε επεξεργασία', status: 'PROCESSING'},
            {title: 'Ολοκληρωμένη', status: 'COMPLETED'},
            {title: 'Έχει αποσταλλεί', status: 'SHIPPING'},
            {title: 'Αναμένεται πληρωμή', status: 'PENDING_PAYMENT'},
            {title: 'Ακυρωμένη', status: 'CANCELLED'},
            {title: 'Έχει επιστραφεί', status: 'RETURNED'},
        ],
        status_colors: {
            PROCESSING: '#16a34a',
            SHIPPING: '#84cc16',
            PENDING_PAYMENT: '#f59e0b',
            COMPLETED: '#3356A5',
            CANCELLED: '#dc2626',
            RETURNED: '#334155'
        },
        user_roles: [
            {title: 'Προβολή όλων', role: null},
            {title: 'Πελάτες', role: 'CUSTOMER'},
            {title: 'Συνταγογράφοι', role: 'PRESCRIBER'},
            {title: 'Admin', role: 'ADMIN'}
        ],

    },
    mutations: {
        increment (state) {
            state.count++
        },
        setIsLoggedIn(state, value){
            state.isLoggedIn = value;
            if(!value) localStorage.removeItem('isLoggedIn');
            else localStorage.setItem('isLoggedIn', 'true');
        },

        setUser(state, user){
            state.user = user;
            localStorage.setItem('user', JSON.stringify(user));
        },
        
        setToken(state, token){
            state.token = token;
            localStorage.setItem('token', token);
            axios.defaults.headers.common['x-access-token'] = token;
        },
        setLoading(state, val){
            state.loading = val;
        }

    },
    getters:{
        getStatus: (state) => (status) => {
            return state.statuses.find(x => x.status === status).title
        },
    },

    actions:{

        init(context){
console.log('fetching');
            if(!context.state.isLoggedIn) return;
        },

        login(context, data) {
            context.commit("setIsLoggedIn", true);
            context.commit("setUser", data.user);
            context.commit('setToken', data.token);
        },

        logout(context){
            context.commit("setIsLoggedIn", false);
            context.commit("setUser", {});
            context.commit("setToken", null);
        }
    }
});

export default store;