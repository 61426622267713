<template>

  <div id="home" class="h-100">

    <div class="container">
      <div class="row">

        <div class="col-12">
        </div>
      </div>
    </div>


  </div>

</template>
<style lang="scss">
#home{
  height:100%;
  .text-switcher{
    color:$primary;
  }
}
</style>
<script>

import {mapState} from "vuex";

export default{
  computed:{
    ...mapState({
      count: state => state.count,
      user: state => state.user,
      isLoggedIn: state => state.isLoggedIn
    })
  },

  data(){
    return{
      phrases: ['Movie', 'Series', 'Whatever'],
      selectedPhrase: 'Movie',
      currIndex: 0
    }
  },

  mounted(){
    this.$store.commit('increment');
    this.loopTexts();
    this.$router.push({name: 'orders'})

  },

  methods:{
    loopTexts(){
      setTimeout(()=>{
        if (this.currIndex + 1 > (this.phrases.length - 1)) this.currIndex  =0;
        else this.currIndex +=1;
        this.loopTexts();
      }, 5000);
    }
  }
}


</script>