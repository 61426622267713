var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"users"}},[_c('div',{staticClass:"container-large"},[_c('div',{staticClass:"row align-items-center"},[_vm._m(0),_c('div',{staticClass:"col-auto ml-auto text-right"},[_c('b-button',{attrs:{"size":"sm","variant":"link"},on:{"click":function($event){return _vm.$refs['import-users'].show()}}},[_vm._v("Μαζική εισαγωγή από CSV")])],1)])]),_c('div',{staticClass:"container-large my-3"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 col-md-4"},[_c('b-form-input',{staticClass:"py-2",attrs:{"debounce":"500","placeholder":"Αναζήτηση χρηστών..."},model:{value:(_vm.query.search),callback:function ($$v) {_vm.$set(_vm.query, "search", $$v)},expression:"query.search"}})],1)])]),_c('div',{staticClass:"container-large"},[_c('div',{staticClass:"d-flex align-items-center filters-wrap mt-4"},_vm._l((_vm.user_roles),function(item){return _c('div',{key:item.title,staticClass:"status-filter mb-4 flex-shrink-0 cursor-pointer mr-3",class:item.role === _vm.query.role ? 'active' : '',on:{"click":function($event){_vm.query.role = item.role}}},[_vm._v(" "+_vm._s(item.title)+" ")])}),0)]),_c('div',{staticClass:"container-large mt-4"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('vue-good-table',{ref:"orders-table",attrs:{"mode":"remote","pagination-options":{
              enabled: true,
              total: _vm.totalUsers,
              setCurrentPage: this.query.page,
              dropdownAllowAll: false,
              perPage: this.query.limit,
              rowsPerPageLabel: 'Ανά σελίδα',
              nextLabel:'Επόμενη',
              prevLabel: 'Προηγούμενη',
              perPageDropdown: [10, 15, 20, 25],
            },"totalRows":_vm.totalUsers,"sort-options":{ enabled: false },"style-class":"vgt-table striped condensed","theme":"polar-bear","columns":_vm.columns,"rows":_vm.users},on:{"on-page-change":params => this.query.page = params.currentPage,"on-row-click":params => _vm.redirectTo('users-slug', {userId: params.row.id}),"on-per-page-change":params => this.query.limit = params.currentPerPage},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field === 'fullName')?_c('div',[_vm._v(" "+_vm._s(props.row.firstName)+" "+_vm._s(props.row.lastName)+" ")]):(props.column.field === 'role')?_c('div',[_c('b-badge',{attrs:{"variant":_vm.getVariant(props.row)}},[_vm._v(_vm._s(_vm.getRole(props.row)))])],1):_c('div',[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")])]}}])},[_c('div',{attrs:{"slot":"emptystate"},slot:"emptystate"},[_vm._v(" Δεν βρέθηκαν χρήστες ")])])],1)])]),_c('b-modal',{ref:"import-users",attrs:{"id":"import-users","title":"Import users","hide-footer":""}},[_c('upload',{attrs:{"max-files":1,"url":_vm.getUploadCsvUrl},on:{"addedImage":function($event){return _vm.$refs['import-users'].hide()}}})],1)],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-auto"},[_c('h2',[_vm._v("Χρήστες")])])
}]

export { render, staticRenderFns }