import slugify from "slugify";
export default{

    data(){
        return{
            years: [2022,2023,2024,2025,2026,2027,2028,2029,2030],
            months:{
                0:'Ιανουάριος',
                1:'Φεβρουάριος',
                2:'Μάρτιος',
                3:'Απρίλιος',
                4:'Μάιος',
                5:'Ιούνιος',
                6:'Ιούλιος',
                7:'Αύγουστος',
                8:'Σεπτέμβριος',
                9:'Οκτώβριος',
                10:'Νοέμβριος',
                11:'Δεκέμβριος'
            }
        }
    },
    methods:{
        redirectTo(name, params){

            if(params) return this.$router.push({name: name, params: params});
            return this.$router.push({name: name})
        },

        slugFromName(name){

            return slugify(name, {
                lower: true,
                strict: true,
                trim: true
            })
        },
        formatDate(date) {
            if(!date) return null;
            let year = date.getFullYear();
            let month = (1 + date.getMonth()).toString().padStart(2, '0');
            let day = date.getDate().toString().padStart(2, '0');

            return `${day}/${month}/${year}`;
        },
        formatDateWithTime(date){
            if(!date) return null;

            let dateStr = this.formatDate(date);

            let timeStr = date.getHours() + ':' + date.getMinutes().toString().padStart(2, '0');

            return `${dateStr} ${timeStr}`
        }
    }
}